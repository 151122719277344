import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DataTable } from '../display';
import '../design.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GridColDef } from '@mui/x-data-grid';
import { Navigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { Box, IconButton, CircularProgress, Card, CardMedia, CardContent, Typography, CardActions, Modal, Button, TextField, Checkbox, Chip, Menu, MenuItem, Select, FormControl, InputLabel, Switch } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import DeleteIcon from "@mui/icons-material/Delete";


import * as Important from "../important";
import * as Messages from '../messages';
import { httpClient } from '../requests';
import { inherits } from 'util';


interface UserUpdateData {
  userCategoryToSet: number;
  userEmailToSet: string;
  userFirstNameToSet: string;
  userLastNameToSet: string;
  userPhoneNumberToSet: string;
  userHasCompanyToSet: boolean;
  userHasIndustryToSet: boolean;
  userIsAdminToSet: boolean;
  userIsApprovedToSet: boolean;
  userIsEnabledToSet: boolean;
}

interface UserCategory {
  categoryId: number;
  categoryName: string;
  categoryPixels: number;
}


interface Case {
  caseNumId: number;
  caseName: string;
  caseButtonName: string;
  caseButtonModalName: string;
  caseOperationToDo: string;
}


export function Users(): JSX.Element {

  const usersUrl = Important.userUrl;
  const categoryUrl = Important.categoryUrl;

  const [result, setResult] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const admin = JSON.parse(localStorage.getItem('admin') || 'false');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const [isApproveUserModalOpen, setIsApproveUserModalOpen] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<any>("");
  const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false);
  const [currentUserToUpdateId, setCurrentUserToUpdateId] = useState<number>(0);

  const [userUpdateData, setUserUpdateData] = useState<UserUpdateData| any>();
  const [userCategories, setUserCategories] = useState<UserCategory[] | any[]>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);


  







  const cases: Case[] = [
    { caseNumId:1, caseName: 'not_approved', caseButtonName: Messages.userIsNotApprovedMessage, caseButtonModalName: Messages.setUserApprovedConfirmationModalMessage, caseOperationToDo: Messages.setUserApprovedConfirmationMessage   },
    { caseNumId:2, caseName: 'inactive', caseButtonName: Messages.userIsNotActiveMessage, caseButtonModalName: Messages.setUserActiveConfirmationModalMessage, caseOperationToDo: Messages.setUserActiveConfirmationMessage   },
    { caseNumId:3, caseName: 'active', caseButtonName: Messages.userIsActiveMessage, caseButtonModalName: Messages.setUserNotActiveConfirmationModalMessage, caseOperationToDo:Messages.setUserNotActiveConfirmationMessage   },
    { caseNumId:4, caseName: 'is_not_admin', caseButtonName: Messages.userIsNotAdminMessage, caseButtonModalName: Messages.setUserAsAdminConfirmationModalMessage, caseOperationToDo: Messages.setUserAsAdminConfirmationMessage   },
    { caseNumId:5, caseName: 'is_admin', caseButtonName: Messages.userIsAdminnMessage, caseButtonModalName: Messages.unsetUserAsAdminConfirmationModalMessage, caseOperationToDo: Messages.unsetUserAsAdminConfirmationMessage   },
    { caseNumId:6, caseName: 'is_not_permitted_to_be_admin', caseButtonName: Messages.userIsNotPermittedToBeAdminMessage, caseButtonModalName: Messages.userIsNotPermittedToBeAdminModalMessage, caseOperationToDo: Messages.userIsNotPermittedToBeAdminMessage   },
    { caseNumId:7, caseName: 'category', caseButtonName: '', caseButtonModalName: '', caseOperationToDo: ''   },
  ];

  const {
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      projectId: ''
    },
  });


  function DisplayChip({ text, updateFn }: { text?: string, updateFn?: any }) {
    return (
      <Chip onClick={() => updateFn()} label={text}  
      variant="outlined" 
      color='primary'/>
    );

  }

  function DisplaySwitch({ updateFn, defaultChecked, disabled }: { updateFn?: any, defaultChecked?: boolean, disabled?: boolean }) {
    return (
      <Switch onClick={() => updateFn()} size = "small" defaultChecked ={defaultChecked} disabled = {disabled}/>
    );

  }



  const defineCaseToStateField = (is_approved: boolean, is_enabled: boolean) => {
    let result = 1;
    if (is_approved) {
      result = 2;
      if (is_enabled)
        result = 3;
    }
    return result;
  }

  const defineCaseToAdminField = (is_admin: boolean, is_approved: boolean, is_enabled: boolean) => {
    let result = 0;
    if (!is_approved || !is_enabled)
      return result = 6;

    if (!is_admin) result = 4;
    else result = 5;
    return result;
  }

  const defineUserUpdateDataBasedOnCaseId = (caseId: number, newNumberToSet?:number, newTextToSet?:string) => {
    const updateDataMap: any = {
      1: { userIsApprovedToSet: true, userCategoryToSet: newNumberToSet },
      2: { userIsEnabledToSet: true },
      3: { userIsEnabledToSet: false },
      4: { userIsAdminToSet: true },
      5: { userIsAdminToSet: false },
      7: { userCategoryToSet: newNumberToSet },
    };

    const updateUserUpdateData = updateDataMap[caseId];
    
    if (updateUserUpdateData) {
      setUserUpdateData(updateUserUpdateData);
    }
  };


  const openModalBasedOnCaseId = (caseId: number) => {
    if(caseId===6) setIsWarningModalOpen(true);
    else {
      if(caseId===1) setIsApproveUserModalOpen(true);
      else setIsConfirmationModalOpen(true);
    }
  };

  const handleSelectCategoryModal = (event: any) => {
    const categoryName = event.target.value;
    const categoryId = userCategories.find(userCategories => userCategories.name === categoryName)?.id;
    defineUserUpdateDataBasedOnCaseId(1,categoryId);
  };
  
  
  
  
  

  const tableColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 180 },
    { field: 'firstname', headerName: 'Όνομα', width: 180 },
    { field: 'lastname', headerName: 'Επίθετο', width: 180 },
    { field: 'email', headerName: 'Email', width: 180 },
    { field: 'country', headerName: 'Χώρα', width: 180 },
    { field: 'category_name',
    headerName: 'Κατηγορία',
    width: 180,
    renderCell: (cellValues) => {

      let category=cellValues?.value;

      const handleChange = (event: any) => {
        category=event.target.value;
        setIsConfirmationModalOpen(true);
        setModalMessage(Messages.changeUserCateogryModalMessage+event.target.value+' ;');
        setCurrentUserToUpdateId(cellValues?.row?.id);
        defineUserUpdateDataBasedOnCaseId(7,userCategories.find(userCategories => userCategories.name === event.target.value)?.id );
        setAnchorEl(null);
      };
    
      return (
        <>
          <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={category}
            onChange={handleChange}
            > 
           {userCategories.map((item: any) => {

            return (
              <MenuItem key={item.id} value={item.name} >{item.name}</MenuItem>);
           })}
           
          </Select>
          </FormControl>

        </>
      );
    },
    },
    {
      field: 'state', headerName: 'Κατάσταση', width: 180,
      renderCell: (cellValues: any) => {

        return (
          <DisplayChip
          
            text={cellValues?.value}
            updateFn={() => {
              openModalBasedOnCaseId(cellValues?.row?.state_case_id);
              if(cellValues?.row?.state_case_id!==1) 
                setModalMessage(cases.find(cases =>  cases.caseNumId === cellValues?.row?.state_case_id)?.caseButtonModalName);
          
              setCurrentUserToUpdateId(cellValues?.row?.id);
              defineUserUpdateDataBasedOnCaseId(cellValues?.row?.state_case_id);
            }}

          />
        );
      }
    },
    {
      field: 'is_admin', headerName: 'Διαχειριστής', width: 180,
      renderCell: (cellValues: any) => {
        let switchDisabled = false;
        let switchDefaultChecked = false;
        if(cellValues?.row?.admin_case_id === 6) switchDisabled = true;
        else if(cellValues?.row?.admin_case_id === 5) switchDefaultChecked = true;
        return (
        <DisplaySwitch 
            updateFn={() => {
              openModalBasedOnCaseId(cellValues?.row?.admin_case_id);
              setModalMessage(cases.find(cases =>  cases.caseNumId === cellValues?.row?.admin_case_id)?.caseButtonModalName);
              setCurrentUserToUpdateId(cellValues?.row?.id);
              defineUserUpdateDataBasedOnCaseId(cellValues?.row?.admin_case_id);
            }}
            defaultChecked = {switchDefaultChecked}
            disabled = {switchDisabled} />
        );
      }
    },
    { field: 'bounded_pixels', headerName: 'Δεσμευμένα GPixel', width: 180 },
    { field: 'used_pixels', headerName: 'Καταναλωμένα GPixel', width: 180 },
    {
      field: 'edit',
      headerName: '',
      width: 180,
      renderCell: (cellValues: any) => {
        return (
          <div>
          <IconButton disabled = {cellValues?.row?.hasProjects} color="warning"
            onClick={() => deleteUser(cellValues, cellValues?.row?.id)}>
            <DeleteIcon />
          </IconButton>
        </div>
        );
        
      }
    },

  ];


  function alterTable(cellValues: any) {
    setResult(prevResult => {
      const updatedResult = prevResult.filter(row => row.id !== cellValues?.row?.id);
      return updatedResult;
    });
  }

  async function deleteUser(cellvalues: any, id: number) {
    const requestUrl = usersUrl + '/' + id;
    try {
      const response: any = await httpClient.delete(requestUrl);
      alterTable(cellvalues);
      toast.success(Messages.deleteUserSuccess);
    }
    catch (error: any) {
      throw error;
      //toast.error(error.response?.data.message || Messages.deleteUserError);
    }
  }



  const displayDivs = (divs: any) => (
      <DataTable
        rows={divs.map((row: any) => ({
          ...row,
          created: moment(row.created).format('MM/DD/YYYY'),
        }))}
        tableColumns={tableColumns}
      />
  );




  async function updateUser(userId: number) { 
    let requestUrl = usersUrl + "/admin/users/" + userId;
    try {
      const response = await httpClient.post(requestUrl, userUpdateData);
      toast.success(Messages.updateUserAsAdminSuccessMessage);
      setIsConfirmationModalOpen(false);
      getData();

    } catch (error: any) {
      throw error;
      //toast.error(error.response?.data.message || Messages.updateUserAsAdminErrorMessage)
    }
  }




  async function getData() {
    try {
      setIsLoading(true);
      let requestUrl = usersUrl + "/getall/withplusdata";
      const response: any = await httpClient.get(requestUrl);
      const {categories} = response.data;
      
      const {usersWithPlusData} = response.data;
      
      const users = usersWithPlusData.map((item: any) => {
        let { id, firstname, lastname, email, country, category_name, is_admin, is_approved, is_enabled, project_total_pixels, category_pixels, category_id, projects_number } = item;
        let state_case_id=defineCaseToStateField(is_approved, is_enabled);
        let admin_case_id=defineCaseToAdminField(is_admin, is_approved, is_enabled);
        let hasProjects = true;

 

        if (project_total_pixels === null)
          project_total_pixels = 0;
        if (category_pixels === null )
          category_pixels = 0;
        if (category_name === null )
          category_name = '';
        if(projects_number === null)
           hasProjects = false;


        return {
          id: id,
          firstname: firstname,
          lastname: lastname,
          email: email,
          country: country,
          category_name: category_name,
          state: cases.find(cases =>  cases.caseNumId === state_case_id)?.caseButtonName,
          is_admin: cases.find(cases =>  cases.caseNumId === admin_case_id)?.caseButtonName,
          bounded_pixels: Number(category_pixels / 1000000000).toFixed(3),
          used_pixels: Number(project_total_pixels / 1000000000).toFixed(3),

          state_case_id: state_case_id,
          admin_case_id: admin_case_id,
          hasProjects: hasProjects
        }


      });
      setResult(users);
      setUserCategories(categories);


      

    }
    catch (error: any) {
      throw error;
      //toast.error(error.response?.data.message);
    } finally {
      setIsLoading(false);
    }
  }


  async function getCategories() {
    try {
      let requestUrl = categoryUrl;
      const response: any = await httpClient.get(requestUrl);
      setUserCategories(response.data);

    }
    catch (error: any) {
      throw error;
      //toast.error(error.response?.data.message);
    } finally {
      setIsLoading(false);
    }
  }


  useEffect(() => {
    getData();
  }, []);



  return <>

    {admin ? (
      <div>
        {isLoading ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2 }}>
            <CircularProgress size={30} sx={{color:'#BA1E4C'}}/>
          </Box>
        ) : (
          <>
            <Modal
              open={isConfirmationModalOpen}
              onClose={() => setIsConfirmationModalOpen(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                width: 300,
                textAlign: 'center',
              }}>
                <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                  {modalMessage}
                </Typography>
                <div style={{ marginTop: "10px" }}>
                  <Button onClick={() => {
                       updateUser(currentUserToUpdateId);
                       setUserUpdateData({});
                       setIsConfirmationModalOpen(false);
                     
                      
                    }} variant="contained" color="primary" sx={{ mr: 2 }}>
                    ΝΑΙ
                  </Button>
                  <Button
                    onClick={() => {
                    setIsConfirmationModalOpen(false);
                    setUserUpdateData({});
                    }}
                  variant="contained"
                  color="error"
                  >
                  ΟΧΙ
                </Button>
                </div>
              </Box>
            </Modal>

            <Modal
              open={isApproveUserModalOpen}
              onClose={() => setIsApproveUserModalOpen(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                width: 300,
                textAlign: 'center',
              }}>
                <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                  {Messages.setNewUserCtaegoryMenuTitle}
                </Typography>
                <div style={{ marginTop: "10px" }}>
                  <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"

            onChange={handleSelectCategoryModal}
            > 
           {userCategories.map((item: any) => {

            return (
              <MenuItem key={item.id} value={item.name} >{item.name}</MenuItem>);
           })}
          </Select>
          </FormControl>
          <Button
            style={{ marginTop: "10px" }}
            onClick={() => {
              updateUser(currentUserToUpdateId);
              setUserUpdateData({});
              setIsApproveUserModalOpen(false);
            
          }}
         variant="contained"
         color="primary"
        sx={{ mr: 2 }}
        >
       ΑΠΟΔΟΧΗ
       </Button>
          <Button
            style={{ marginTop: "10px" }}
            onClick={() => {
            setIsApproveUserModalOpen(false);
            
          }}
         variant="contained"
         color="error"
        sx={{ mr: 2 }}
        >
       ΑΚΥΡΩΣΗ
       </Button>
                </div>
              </Box>
            </Modal>


            <Modal
              open={isWarningModalOpen}
              onClose={() => setIsWarningModalOpen(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                width: 300,
                textAlign: 'center',
              }}>

                <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                  {modalMessage}
                </Typography>
                <div style={{ marginTop: "10px" }}>
                  <Button onClick={() => setIsWarningModalOpen(false)} variant="contained" color="primary" sx={{ mr: 2 }}>
                    ΕΠΙΣΤΡΟΦΗ
                  </Button>
                </div>
              </Box>
            </Modal>

            <Box
              mt={5}
              alignItems='center'
              display="flex"
              justifyContent="center"
              flexDirection='column'>
              <Typography variant='h5' sx={{ mt: 2 }}>
                Οι χρήστες
              </Typography>  

            </Box>
              <div style={{ display: 'flex', justifyContent:'center',marginTop: "20px", width: "500" }}>
                {displayDivs(result)}
              </div>
          </>

        )}
      </div>) : (<Navigate to="/projects" />)
    }
  </>;
}


