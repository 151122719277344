import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { HashRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './components/theme';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>

<ThemeProvider theme={theme}>
    <HashRouter>
        <App />
    </HashRouter>
    </ThemeProvider>
  </React.StrictMode>


);

