import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DataTable } from '../display';
import '../design.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { Box, IconButton, CircularProgress, Card, CardMedia, CardContent, Typography, CardActions, useTheme, useMediaQuery } from "@mui/material";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from '@mui/material';
import MuiTextField from '../components/MuiTextField';
import { CenteredContainer } from '../components/CenteredForm';
import axios from "axios";
import ImageAspectRatioIcon from '@mui/icons-material/ImageAspectRatio';
import * as Important from "../important";
import * as Messages from '../messages';
import * as Display from '../display';
import * as Utilities from '../utilities';
import { httpClient } from '../requests';
import NotificationBox from '../components/NotificationBox';
import { pixelsToGigaPixels } from '../components/PixelsConverter';




export function Projects(): JSX.Element {


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const projectUrl = Important.projectUrl;
  const navigate = useNavigate();

  const [userLimitPixels, setUserLimitPixels] = useState<any>(0);
  const [userUsedPixels, setUserUsedPixels] = useState<any>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);


  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      projectId: ''
    },
  });

  const tableColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', flex:0.1, minWidth: 180 },
    { field: 'name', headerName: 'Όνομα', flex: 0.1, minWidth: 220 },
    { field: 'created', headerName: 'Δημιουργία', flex: 0.1, minWidth: 200 },
    { field: 'status', headerName: 'Κατάσταση', flex: 0.1, minWidth: 200 },
    { field: 'images', headerName: 'Εικόνες', flex: 0.1, minWidth: 200 },
    { field: 'total_pixels', headerName: 'Total pixels', flex: 0.1, minWidth:220 },
    {
      field: 'details',
      headerName: 'Πληροφορίες',
      flex: 0.1,
      minWidth: 300,
      renderCell: (cellValues) => (
        <IconButton sx={[{opacity: 0.8, color:'#BA1E4C'}]}
          onClick={() => handleDetailsClick(cellValues)}>
          <ReadMoreIcon />
        </IconButton>
      )
    },
  ];




  const [result, setResult] = useState<any[]>([]);
  const [userProjectIds, setUserProjectIds] = useState<string[]>([]);
  const [searchResult, setSearchResult] = useState<any[]>([]);


  function alterTable(cellValues: any) {
    setResult(prevResult => {
      const updatedResult = prevResult.filter(row => row.id !== cellValues?.row?.id);
      const updatedUserProjectIds = userProjectIds.filter(id => id !== cellValues?.row?.id);
      setUserProjectIds(updatedUserProjectIds);
      return updatedResult;
    });
  }



  const handleDetailsClick = (cellValues: any) => {
    navigate('/details?id=' + cellValues?.row?.id);
  };



  const displayDivs = (divs: any[]) => (
      <DataTable
        rows={divs.map((row) => ({
          ...row,
          created: moment(row.created).format('MM/DD/YYYY'),
          
        }))}
        tableColumns={tableColumns}
      />
  );


  async function getProjectsByUser() {
    try {
      setIsLoading(true)
      let requestUrl = projectUrl+'byuser';
      const response: any = await httpClient.get(requestUrl);
      const projects = response.data.map((item: any) => {
        const { id, name, create_date, public_status, image_count, total_pixels } = item;
           return {
              id,
              name,
              created: create_date,
              status: public_status,
              images: image_count,
              total_pixels: pixelsToGigaPixels(total_pixels).toFixed(3),
            }
      });
      setResult(projects);
    }
    catch (error: any) {
      console.log(error);
      return [];
      //toast.error(error.response?.data.message || Messages.getProjectError);
    } finally {
      setIsLoading(false)
    }
  }



  async function setUserPixels() {
    let userLimitPixels =  await Utilities.getUserPixels(true, false,0);
    let userUsedPixels =  await Utilities.getUserPixels(false, false,0);
    if(userUsedPixels==='') userUsedPixels = 0;
    setUserLimitPixels(userLimitPixels);
    setUserUsedPixels(userUsedPixels);


  }



  useEffect(() => {
    getProjectsByUser();
  }, []);

  useEffect(() => {
    setUserPixels();
  }, []);


  return (
    <div >
      {isLoading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2 }}>
          <CircularProgress size={30} sx={{color:'#BA1E4C'}}/>
        </Box>
      ) : (
        <>
          <Box
            mt={5}
            alignItems='center'
            display="flex"
            justifyContent="center"
            flexDirection='column'>
            <Box display="flex" gap={4} flexDirection={isMobile ? 'column' : 'row'} justifyContent="center" alignItems="center">
              <NotificationBox isLoading={isLoading} number={userLimitPixels?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')} title={'Οριο pixels'} icon={<ImageAspectRatioIcon />} />
              <NotificationBox isLoading={isLoading} number={userUsedPixels?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')} title={'Pixels που καταναλώθηκαν:'} icon={<ImageAspectRatioIcon />} />
            </Box>

            <Typography variant='h5' sx={{ mt: 2 }}>
              Τα projects σας
            </Typography>

          </Box>
            <div style={{ display: 'flex', justifyContent:'center',marginTop: "20px" }}>
              {displayDivs(result)}
            </div>
        </>

      )}
    </div>
  );

}

