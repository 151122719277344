import { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { AuthContext } from "./App";

export const PrivateRoutes = () => { //{isLoggedIn}:{isLoggedIn:boolean}

  const { isLoggedIn } = useContext(AuthContext);

    return (isLoggedIn ? 
      <Outlet />
     : 
      <Navigate to="/signin"  replace/>
    );
};