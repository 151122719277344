import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Button, TextField, Typography, Container, Paper, Box } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { httpClient } from '../requests';
import { toast } from 'react-toastify';

// Validation Schema
const schema = yup.object().shape({
  email: yup.string().required('Email is required').email('Invalid email address'),
});

type FormData = {
  email: string;
};

 function ForgotPassword() {
  const { control, handleSubmit, formState: { errors } } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  const onSubmit = async (data: FormData) => {
    try {
      const response = await httpClient.post('auth/forgot-password', data);
      toast.success('Θα σας σταλεί mail σύντομα για να αλλάξετε τον κωδικό σας');
    }catch(error){
      console.log(error);
    }
  };

  const handleCancel = () => {
    navigate('/signin');
  }

  return (
    <Container component={Paper} maxWidth="xs" style={{ padding: '2rem', marginTop: '2rem' }}>
      <Typography variant="h5" gutterBottom>
        Έκδοση νέου κωδικού
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
         name="email"
         control={control}
         defaultValue=""
         render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              label="Email"
              variant="outlined"
              margin="normal"
              error={!!errors.email}
              helperText={errors.email?.message}
            />
            )} 
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>

        <Button type="submit" variant="contained" color="primary" >
          Νεος κωδικος
        </Button>
        <Button variant="outlined" color="secondary" sx={{ml:2}} onClick={handleCancel}>
          Ακυρωση
        </Button>
        </Box>
        
      </form>
    </Container>
  );
}

export default ForgotPassword;
