import { CircularProgress, Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';

function    NotificationBox({ title, number, icon, isLoading = false }: any) {
    return (<>
        <Box p={2}
            style={{
                borderColor: '#7f99ba',
                borderWidth: '1px',
                borderStyle: 'solid',
                width: 'fit-content',
                maxWidth: '87%',
                borderRadius: '25px'
            }}>

            <Box display='flex' gap={2}>

                <Box mt={1} p={1}
                    py={2}
                    px={2}
                    sx={{
                        height: 'fit-content',
                        width: 'fit-content',
                        backgroundColor: 'lightBlue.main',
                        borderRadius: '55px'
                    }}>

                    {icon}
                </Box>

                <Box>

                    <Typography color='secondary'>{title}</Typography>
                    <Divider />
                    <Typography variant='h3' style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingTop: '10px',
                        paddingRight: '10px'
                    }}>
                        {isLoading //|| !number
                            ? <CircularProgress />
                            : number}
                    </Typography>
                </Box>
            </Box>

        </Box>

    </>);
}

export default NotificationBox;