import React, { useState, useEffect } from 'react';
import {  httpClient } from '../requests';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import * as Messages from '../messages';
import * as Important from '../important';
import moment from 'moment';
import Iframe from 'react-iframe';
import * as Display from '../display';
import {DisplayErrorMessage} from '../display';
import axios from "axios";

export function Details(): JSX.Element {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get('id');
  const navigate = useNavigate();

  const [result, setResult] = useState<any>({ data: null });
  const [displayData, setDisplayData] = useState<any[]>([]);
  const [zipUrl, setZipUrl] = useState<any>("");
  const [isZipCreated, setIsZipCreated] = useState<boolean>(false);
  const [isZipAvaliable, setIsZipAvaliable] = useState<boolean>(false);
  const [downloadButtonText, setDownloadButtonText] = useState<string>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const goBack = () => {
    navigate("/projects");
  };

  async function getThisProject() {
    setIsLoading(true)
    const requestUrl = Important.projectUrl + projectId;
    try {
      const response = await httpClient.get(requestUrl);
      setResult({ data: response.data });
    } catch (error: any) {
    } finally { 
    setIsLoading(false)
    }
  }

  function populateDisplayDataArray() {
    if (result.data) {
      setDisplayData([
      { key: 'ID: ', value: result.data.id },
      { key: 'Όνομα: ', value: result.data.name },
      { key: 'Δημιουργία: ', value: moment(result.data.create_date).format('MM/DD/YYYY') },
      { key: 'Κατάσταση: ', value: result.data.public_status },
      { key: 'Σύνδεσμος project: ', value: <a target="_blank" href={result.data.front_end_public_url}> Προβολή</a> },
      { key: 'Εικόνες: ', value: result.data.image_count },
      { key: 'Μήνυμα σφάλματος: ', value: result.data.error_reason }
    ]);
  }
  }

  async function get3dAsZip() {
    const requestUrl=Important.exportUrl+'/zip/'+projectId;
    try {
      const response: any = await httpClient.get(requestUrl) ;
      setZipUrl(response.data);
      setIsZipCreated(true);
      setDownloadButtonText(Messages.zipExportDownloadButtonDownload);
    }
    catch(error) {
    }
  }

  async function checkIf3dZipIsMade() {
    const requestUrl=Important.exportUrl+'/check/'+projectId;
    try {
      const response: any = await httpClient.get(requestUrl) ;
      if(response.data===true) {
        setIsZipAvaliable(true);
        get3dAsZip();
      }
      else {
        setDownloadButtonText(Messages.zipExportDownloadButtonWait);
        setIsButtonDisabled(true);
      }
    }
    catch(error) {

    }
  }

  function DisplayDownloadZipButton() {
    return (
      <div style={{marginTop:"10px",marginBottom:"10px" }}> 
        <div >
         <Button variant="contained" disabled={isButtonDisabled}>
          <a href={zipUrl} style={{ textDecoration: 'none', color: 'inherit' }}>
         <DownloadIcon style={{marginRight:"10px"}}/>
           {downloadButtonText}
         </a>
        </Button>
        </div>
    </div>
    );
    
  }

  function DisplayCreationZipButton() {
    let text = downloadButtonText;
    let textColor = "black"; 
    return (
      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <div >
          <Button
            variant="contained"
            disabled={isButtonDisabled}
            style={{ color: textColor }} 
          >
            {text}
          </Button>
        </div>
      </div>
    );
  }

  function DisplayCreationError() {
    return (
      <div style={{marginTop:"10px",marginBottom:"10px" }}> 
        <div style={{ marginLeft: "620px" }}>
        <DisplayErrorMessage message={Messages.zipExportDownloadButtonCreateError} />
        </div>
      </div>
    ); 
  }


  useEffect(() => {
    getThisProject();
  }, [projectId]);

  useEffect(() => {
    populateDisplayDataArray();
  }, [result.data]);
   
  useEffect(() => {
    checkIf3dZipIsMade();

  }, [isZipAvaliable,zipUrl]);



  const arrayLength=displayData.length;
  

  return (
    <div style={{ marginTop: '20px' }}>
      <div style={{ marginLeft: '20px', marginBottom: '20px' }}>
        <Button variant="contained" onClick={goBack} sx={[{backgroundColor: '#BA1E4C'}, {'&:hover': {backgroundColor: '#BA1E4C', color: 'white'}}]}>
          Επιστροφη
        </Button>
      </div>
      <div>
        {isLoading ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2 }}>
            <CircularProgress size={30} sx={{color:'#BA1E4C'}}/>
          </Box>
        ) : (
          <div>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2,
              padding: '20px', border: '2px solid #ccc', borderRadius: '20px' }}>
            {result.data && (
              result.data.front_end_public_url ? (
                Display.display3dWithIframe(result.data.front_end_public_url)
              ) : ( 
                <DisplayErrorMessage  message = {Messages.getProjectError}  />
              )
            )}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2,
              padding: '20px', border: '2px solid #ccc', borderRadius: '20px' }}>
             <div style={{marginLeft:"30px" }}>
              {result.data ? (
                <div>
                  {Display.displayTitleWithTypography("Πληροφορίες:")}
                  {displayData.map((item, index) => {
                    if (index === arrayLength - 1 && result.data.public_status !== 'ERROR') 
                      return null;
                    return Display.displayFieldWithTypography(item.key, item.value, index);
                  })}
                </div>
            ) : (
              <DisplayErrorMessage  message = {Messages.getProjectError}  />
            )}
              </div>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2,
              padding: '20px', border: '2px solid #ccc', borderRadius: '20px' }}>
              {!isZipAvaliable ? (
                <DisplayCreationZipButton />
              ) : (
                <div>
                  {isZipCreated ? (
                    <DisplayDownloadZipButton />
                  ) : (
                    <DisplayCreationError />
                  )}
                </div>
              )}
            </Box>
          </div>
        )}
      </div>
    </div>
  );

}
  