import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, TextField, Typography, Container, Paper } from '@mui/material';
import { httpClient } from '../requests';

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .min(4, 'Ο κωδικός πρέπει να έχει τουλάχιστον 4 χαρακτήρες')
    .max(20, 'Ο κωδικός δεν πρέπει να ξεπερνάει τους 20 χαρακτήρες')
    .required('Απαιτείται κωδικός'),
  //confirmPassword: yup.string().oneOf([yup.ref('password')], 'Οι κωδικοί πρέπει να ταιριάζουν').required("Απαιτείται επιβεβαίωση κωδικού"),
  confirmPassword: yup.string().required('Απαιτείται επιβεβαίωση κωδικού'),
});

type FormData = {
  newPassword: string;
  confirmPassword: string;
};

function ResetPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const resetToken = queryParams.get('resetToken');

  useEffect(() => {
    if (!resetToken) {
      navigate('/forgot-password');
    }
  }, [resetToken, navigate]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormData) => {
    try {
      const response = await httpClient.post(`auth/reset-password`, {
        resetToken: resetToken,
        newPassword: data.newPassword,
      }); //
    } catch (error) {
      console.log(error);
    } finally {
      navigate('/signin');
    }
  };

  return (
    <Container
      component={Paper}
      maxWidth='xs'
      style={{ padding: '2rem', marginTop: '2rem' }}
    >
      <Typography variant='h5' gutterBottom>
        Αλλαγή Κωδικού
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='newPassword'
          control={control}
          defaultValue=''
          render={({ field }) => (
            <TextField
              type='password'
              fullWidth
              {...field}
              label='Νέος Κωδικός'
              variant='outlined'
              margin='normal'
              error={!!errors.newPassword}
              helperText={errors.newPassword?.message}
            />
          )}
        />
        <Controller
          name='confirmPassword'
          control={control}
          defaultValue=''
          render={({ field }) => (
            <TextField
              type='password'
              fullWidth
              {...field}
              label='Επιβεβαίωση Κωδικού'
              variant='outlined'
              margin='normal'
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword?.message}
            />
          )}
        />
        <Button type='submit' variant='contained' color='primary'>
          Αλλαγη
        </Button>
      </form>
    </Container>
  );
}

export default ResetPassword;
