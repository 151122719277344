import React, { useRef,useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Checkbox, Card, CardContent, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuid } from 'uuid';
import axios from "axios";
import * as yup from "yup";
import MuiTextField from "../components/MuiTextField";
import { CenteredContainer } from "../components/CenteredForm";
import * as Important from "../important";
import * as Messages from '../messages';
import * as Display from '../display';
import { DisplayErrorMessage } from '../display';
import { httpClient } from "../requests";
import NotificationBox from '../components/NotificationBox';
import ImageAspectRatioIcon from '@mui/icons-material/ImageAspectRatio';


const UploadPhotoSchema = yup.object().shape({
  photos: yup.array().of(yup.mixed()).required("Παρακαλώ προσθέστε φωτογραφίες."),
  name: yup.string().required("Παρακαλώ προσθέστε το όνομα του project που θα δημιουργηθεί."),
});

interface NewSelectedItem {
  id: string;
  file: File;
  pixels: number;
}

export function Submissions(): JSX.Element {
  const navigate = useNavigate();
  const fileInputRef: any | null = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState<any[] >([]);
  const [selectedImagesToUnselect, setSelectedImagesToUnselect] = useState<any[]>([]);
  const [userAvaliablePixels, setUserAvaliablePixels] = useState<number>(0);
  const [userToUsePixels, setUserToUsePixels] = useState<number>(0);
  const [virtualUserToUsePixels, setVirtualUserToUsePixels] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isImagesLoading, setIsImagesLoading] = useState<boolean>(false);


  const uploadRequestUrl = Important.uploadUrl;

  const pixelsRequestUrl = Important.pixelsUrl;


  const { setValue, handleSubmit, formState: { errors }, reset, control, register } = useForm({
    defaultValues: {
      photos: [],
      name: "",
    },
    resolver: yupResolver(UploadPhotoSchema),
  });

  
  const checkIfAvaliablePixelsAreEnough = (): boolean => {
    let limit=0;
    if(userAvaliablePixels<=limit) return false;
    return true;
  }


  const handleImageCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, imageId: string) => {
    const item: NewSelectedItem = getItemFromSelectedFiles(imageId);
    let numberPassed;
    if (event.target.checked) {
      setSelectedImagesToUnselect([...selectedImagesToUnselect, imageId]);
      numberPassed=-item.pixels;

    } else {
      setSelectedImagesToUnselect(selectedImagesToUnselect.filter((id) => id !== imageId));
      numberPassed=+item.pixels;

    }
    alterVirtualUserToUsePixels(numberPassed);
  };
  
  
  const handleFormReset = () => {
    reset({ photos: [], name: "" });
    setSelectedFiles([]);
    setUserToUsePixels(0);
    setVirtualUserToUsePixels(0);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; 
    }
  };
  const getItemFromSelectedFiles = (id: string): any => {
    let foundItem = null;
    selectedFiles.forEach((item, index) => {
      if (item.id === id) {
        foundItem = item;
        return; 
      }
    });
    return foundItem;
  };

  const deleteItemFromSelectedFiles = async (id: string) => {
    await setSelectedFiles(prevResult => {
      const updateSelectedFiles =  prevResult.filter(row => row.id !== id);
      return updateSelectedFiles;
    });
  }

  const alterUserToUsePixels = async (substract: number) => {
    const newUserToUsePixels=userToUsePixels-substract;
    await setUserToUsePixels(newUserToUsePixels);
  }

  const alterVirtualUserToUsePixels = async (number: number) => {
    const newVirtualUserToUsePixels=virtualUserToUsePixels+number;
    await setVirtualUserToUsePixels(newVirtualUserToUsePixels);
  }

  const getAvaliablePixels = async () => {
    let url: string=pixelsRequestUrl;
    try {
      url+="/avaliable";
      const response = await httpClient.get(url);
      return response.data;
    }
    catch (error) {
      console.log(error);
      return [];
      //throw error;
    }
  
  }


  const calculateImagePixels = async (image: any) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(image);
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const context: any | null = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0);
  
        const imageData = context.getImageData(0, 0, img.width, img.height);
        const pixels = imageData.data.length / 4; 
        resolve(pixels);
      };
      img.onerror = (error) => {
        reject(error);
      };

    });
  };


  const deleteSelectedImage = async(imageId: string) => {
    const item: NewSelectedItem  =getItemFromSelectedFiles(imageId);
    await deleteItemFromSelectedFiles(imageId);
    return item.pixels;
  }

  const handleDeleteSelectedImages = async () => {
    let totalPixels=0;
    let count=1;
    for (const item of selectedFiles) {
      if(selectedImagesToUnselect.includes(item.id)) {
        await deleteSelectedImage(item.id)
        .then((pixels) => {
          totalPixels += pixels;
          count++;
      });
      }
    }
    await alterUserToUsePixels(totalPixels);
    await setSelectedImagesToUnselect([]); 
  };
  


  const onSubmit = async (data: any) => {
    let selectedFilesBuffer: any = []; 
    selectedFiles.forEach((item: any)=> {
        selectedFilesBuffer.push(item.file);
    })
    setValue("photos", Array.from(selectedFilesBuffer));  
    const formData = new FormData();
    const files = Array.from(data.photos);
    const pixelsUsed: any=userToUsePixels;
    try {
      setIsLoading(true);
      files.forEach((file: any) => {
        formData.append("photos", file); 
      });
      formData.append("name", data.name);
      formData.append("pixelsUsed", pixelsUsed);
      
      const response = await httpClient.post(
        "upload/submissions",
        formData
      );
      const projectId = response.data;
      toast.success(Messages.createProjectsSuccess);
      handleFormReset();
      navigate('/details?id='+projectId);
    } catch (error: any) {
      console.log(error);
      toast.error(error.response?.data.message || Messages.createProjectError);
    }finally {
      setIsLoading(false);
    }
  };

  const handleManyFilesInputChange = async (event: any) => {
    const files = event.target.files;
    const newFiles = Array.from(files);
    let newSelectedFilesItems: any = [];
    let imagesPixelValue: any = 0;
    let imagePixelValue: any = 0;
    let currentUserToUsePixels = userToUsePixels;
    let newUserToUsePixels = 0;
    setIsImagesLoading(true);

    for (const file of newFiles) {
      await calculateImagePixels(file)
        .then((pixels) => {
          imagePixelValue = pixels;
          imagesPixelValue += imagePixelValue;
        });
      let newSelectedArrayItem: NewSelectedItem = {
        id: uuid(),
        file: file as File,
        pixels: imagePixelValue ,
      };  
      newSelectedFilesItems.push(newSelectedArrayItem);
      
    }

    newUserToUsePixels = currentUserToUsePixels + imagesPixelValue;
  
    if (userAvaliablePixels-newUserToUsePixels > 0) {
      setUserToUsePixels(newUserToUsePixels);
      setVirtualUserToUsePixels(newUserToUsePixels);
      setSelectedFiles((prevFiles) => [...prevFiles, ...newSelectedFilesItems]);
      setIsImagesLoading(false);
  
    } else {
      toast.error("Οι εικόνες που ανεβάσατε υπερβαίνουν το όριο των διαθέσιμων pixel. Επιλέξτε λιγότερες.");
    }
  };


  async function setAvaliableUserPixels() {
    let avaliablePixels = await getAvaliablePixels();
    setUserAvaliablePixels(avaliablePixels);
  }

  

  const groupIntoRows = (data: any[], itemsPerRow: number) => {
    const rows = [];
    for (let i = 0; i < data.length; i += itemsPerRow) {
      rows.push(data.slice(i, i + itemsPerRow));
    }
    return rows;
  };

  const imagesPerRow = 3;
  const groupedFiles = groupIntoRows(selectedFiles, imagesPerRow);

  useEffect(() => {
    setAvaliableUserPixels();
  }, [userToUsePixels, selectedFiles]);

  
return (
 <div>
    {userAvaliablePixels === 0 ? (
       <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2 }}>
       <CircularProgress size={30} sx={{color: '#BA1E4C'}}/>
      </Box>
    ) : checkIfAvaliablePixelsAreEnough() ? (
      <div style={{ marginTop: "30px" }}>
            <Box
            mt={5}
            alignItems='center'
            display="flex"
            justifyContent="center"
            flexDirection='column'>
              <Box display="flex" gap={4}>
                <NotificationBox  number={userAvaliablePixels?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')} title={'Εναπομείναντας χώρος'} icon={<ImageAspectRatioIcon />} />
                <NotificationBox  number={virtualUserToUsePixels?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')} title={'Οριο pixels'} icon={<ImageAspectRatioIcon />} />
              </Box>
              <Box style={{marginTop: "30px" }} sx={{ width: "300px", marginTop: "20px", marginBottom: "20px"  }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <MuiTextField
                    {...register("name", { required: true })}
                    control={control}
                    errors={errors}
                    type="text"
                    name="name"
                    label="Όνομα project"
                    required
                  />
        
                  <input
                    type="file"
                    name="photos"
                    onChange={handleManyFilesInputChange}
                    ref={(ref) => {
                      register("photos", { required: true });
                      fileInputRef.current = ref;
                    }}
                    accept="image/*"
                    multiple
                    required
                    style={{ marginBottom: "10px" }}
                  />
                  {isLoading ? (
                    <div className="loader-container">
                      <CircularProgress size={30} sx={{color:'#BA1E4C'}}/>
                    </div>
                  ):(
                  <div style={{ marginTop: "10px" }}>
                    <Button type="submit" variant="contained" sx={[{ marginRight: "10px",  backgroundColor: '#BA1E4C'}, {'&:hover': {backgroundColor: 'white', color: '#BA1E4C', borderColor:'#BA1E4C'}}]}> 
                      Υποβολη
                    </Button>
                    <Button type="button" variant="outlined" sx={[{color:'#BA1E4C', borderColor:'#BA1E4C'}, {'&:hover': {backgroundColor:'#BA1E4C',borderColor:'#BA1E4C', color:'white'}}]} onClick={handleFormReset}>
                      Ανανεωση
                    </Button>
                    {selectedFiles.length > 0 && (
                      <Button type="button" variant="outlined" onClick={handleDeleteSelectedImages} sx={{ marginTop: "05px", color:'#BA1E4C', borderColor:'#BA1E4C' }}>
                        Διαγραφή επιλεγμένων
                      </Button>
                    )}
                  </div>
                  )}
                </form>
              </Box>


                {/* <Box sx={{  marginTop: '30px', width: '100px' }}> */}
                {isImagesLoading ? (
                    <div className="loader-container">
                      <CircularProgress size={30} sx={{color:'#BA1E4C'}}/>
                    </div>
                  ):(
                  <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {groupedFiles.map((row: any[], rowIndex: number) => (
                      <div key={rowIndex} style={{ display: 'flex', gridTemplateColumns: 'repeat(3, 1fr)' }}>
                        {row.map((file: any, index: number) => (
                          <div
                            key={index}
                            style={{ position: 'relative', marginRight: '10px', marginBottom: '10px', width: '200px' }}
                          >
                            <img
                              width="200"
                              height="100"
                              src={URL.createObjectURL(file.file as File)}
                              alt={`Image ${index}`}
                              style={{ zIndex: 0 }}
                            />
                            <Checkbox
                              checked={selectedImagesToUnselect.includes(file.id)}
                              onChange={(event) => handleImageCheckboxChange(event, file.id)}
                              style={{ position: 'absolute', bottom: '5px', left: '5px', zIndex: 1, color: 'white', background: "black" }}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </ul>)}
                {/* </Box> */}
              </Box>
              
      </div>
    ) : (
      <CenteredContainer>
      <Box sx={{ width: '300px', padding: '20px', border: '2px solid #ccc', borderRadius: '20px' }}>
        <DisplayErrorMessage message={Messages.noAvaliablePixelsError} />
      </Box>
      </CenteredContainer>
    )}
  </div>
);

};

