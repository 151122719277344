import { Box, Typography } from "@mui/material";

export function  Home(): JSX.Element {
  return (
    <div>
      <Box
        mt={5}
        alignItems='center'
        display="flex"
        justifyContent="center"
        flexDirection='column'>
                
          <Typography variant="h3" fontWeight={700} >
              Welcome to Film Cluster!
          </Typography>
                
      </Box>
    </div>
  );
};


