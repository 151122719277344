import React, { useState, useEffect } from 'react';
import { httpClient } from '../requests';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  DialogContentText,
  Modal,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import * as Messages from '../messages';
import * as Important from '../important';
import moment from 'moment';
import Iframe from 'react-iframe';
import * as Display from '../display';
import { DisplayErrorMessage } from '../display';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';

interface UpdateUserModalProps {
  open: boolean;
  onCloseModal: () => void;
  userId: number | null;
  refetch: boolean;
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}

export function UserProfile(): JSX.Element {
  const [isModalOpen, setModalOpen] = useState(false);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [editUserId, setEditUserId] = useState<number | null>(null);

  const [refetch, setRefetch] = useState<boolean>(false);
  const usersUrl = Important.userUrl;
  const categoryUrl = Important.categoryUrl;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const [result, setResult] = useState<any>({ data: null });
  const [displayData, setDisplayData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const goBack = () => {
    navigate(-1);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const setStateText = (isEnabled: boolean) => {
    let result = 'Ενεργός';
    if (!isEnabled) result = 'Ανενεργός';
    return result;
  };

  const setIsAdminText = (isAdmin: boolean) => {
    let result = 'Συμμετέχει';
    if (!isAdmin) result = 'Δεν συμμετέχει';
    return result;
  };

  const setHasCompanyText = (hasCompany: boolean) => {
    let result = 'Έχει';
    if (!hasCompany) result = 'Δεν έχει';
    return result;
  };

  const setCategoryText = async (categoryId: number) => {
    let result = '';
    try {
      let requestUrl = categoryUrl + '/' + categoryId;
      const response: any = await httpClient.get(requestUrl);
      result = response.data.name;
      return result;
    } catch (error: any) {
      toast.error(error.response?.data.message);
    }
  };

  const handleEditUser = () => {
    setEmail(result.data.email);
    setModalOpen(true);
  };

  async function populateDisplayDataArray() {
    if (result.data) {
      const categoryText = await setCategoryText(result.data.category_id);
      const hasCompanyText = setHasCompanyText(result.data.has_company);
      const stateText = setStateText(result.data.is_enabled);
      const isAdminText = setIsAdminText(result.data.is_admin);
      setDisplayData([
        { key: 'ID: ', value: result.data.id },
        { key: 'Όνομα: ', value: result.data.firstname },
        { key: 'Επίθετο: ', value: result.data.lastname },
        { key: 'Email: ', value: result.data.email },
        { key: 'Χώρα: ', value: result.data.country },
        { key: 'Εταιρεία: ', value: hasCompanyText },
        { key: 'Κατηγορία: ', value: categoryText },
        { key: 'Κατάσταση: ', value: stateText },
        { key: 'Διαχείριση: ', value: isAdminText },
      ]);
    }
  }

  const UpdateUserModal: React.FC<UpdateUserModalProps> = ({
    open,
    onCloseModal,
    userId,
    refetch,
    setRefetch,
  }) => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const handleSubmit = async () => {
      try {
        await httpClient.patch(`users/${userId}`, {
          email: email || null,
          password: password || null,
        });
        toast.success('Τα στοιχεία σας ανανεώθηκαν επιτυχώς!');
        setRefetch(!refetch);
        onCloseModal();
      } catch (error: any) {
        toast.error(
          error.response?.data.message ||
            'Αποτυχία να ανανεώσετε τα στοιχεία σας'
        );
      }
    };

    return (
      <Modal open={open} onClose={onCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            borderRadius: '8px',
            boxShadow: 24,
            p: 4,
            minWidth: 100,
          }}
        >
          <h2>Επεξεργασια Χρηστη</h2>
          <form onSubmit={handleSubmit}>
            <Box>
              <Controller
                name='password'
                control={control}
                render={({ field }) => (
                  <TextField
                    type='password'
                    label='Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                )}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
                gap: '10px',
              }}
            >
              <Button onClick={onCloseModal} variant='outlined'>
                ΑΚΥΡΩΣΗ
              </Button>
              <Button onClick={handleSubmit} variant='contained'>
                ΥΠΟΒΟΛΗ
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    );
  };

  function DisplayCreationError() {
    console.log(Messages.zipExportDownloadButtonCreateError);
    return (
      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        <div style={{ marginLeft: '620px' }}>
          <DisplayErrorMessage
            message={Messages.zipExportDownloadButtonCreateError}
          />
        </div>
      </div>
    );
  }

  async function getUserData() {
    try {
      setIsLoading(true);
      let requestUrl = usersUrl + '/profile/user';
      const response: any = await httpClient.get(requestUrl);
      setResult({ data: response.data });

      return 1;
    } catch (error: any) {
      return [];
      //toast.error(error.response?.data.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getUserData();
  }, [refetch]);

  useEffect(() => {
    populateDisplayDataArray();
  }, [result.data]);

  return (
    <div style={{ marginTop: '20px' }}>
      <div style={{ marginLeft: '20px', marginBottom: '20px' }}>
        <Button
          variant='contained'
          onClick={goBack}
          sx={[
            { backgroundColor: '#BA1E4C' },
            { '&:hover': { backgroundColor: '#BA1E4C', color: 'white' } },
          ]}
        >
          Επιστροφη
        </Button>
      </div>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 2,
            padding: '2px',
          }}
        >
          <CircularProgress size={30} sx={{ color: '#BA1E4C' }} />
        </Box>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            mt={5}
            alignItems='center'
            display='flex'
            justifyContent='center'
            flexDirection='column'
            sx={{
              width: '600px',
              padding: '20px',
              border: '2px solid #ccc',
              borderRadius: '20px',
            }}
          >
            <div>
              {result.data ? (
                <div>
                  {Display.displayTitleWithTypography('Προφίλ:')}
                  {displayData.map((item, index) => {
                    return Display.displayFieldWithTypography(
                      item.key,
                      item.value,
                      index
                    );
                  })}
                </div>
              ) : (
                <DisplayErrorMessage message={Messages.getProjectError} />
              )}
            </div>
            <Button
              variant='outlined'
              sx={[
                { borderColor: '#BA1E4C', color: '#BA1E4C' },
                {
                  '&:hover': {
                    borderColor: '#BA1E4C',
                    color: 'white',
                    backgroundColor: '#BA1E4C',
                  },
                },
              ]}
              onClick={handleEditUser}
            >
              Επεξεργασια
            </Button>
            <UpdateUserModal
              open={isModalOpen}
              onCloseModal={closeModal}
              userId={result.data?.id}
              refetch={refetch}
              setRefetch={setRefetch}
            />
          </Box>
        </div>
      )}
    </div>
  );
}
