import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#BA1E4C',
    },
    
  },
});

export default theme;
