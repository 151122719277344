import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { AuthContext } from '../App';

const logo = require('./assets/images/filmcluster_logo.png');

const pages = [
  { id: 1, name: 'Projects', url: '/projects' },
  { id: 2, name: 'Δημιουργια project', url: '/submissions' },
  { id: 3, name: 'Κατηγοριες', url: '/categories' },
  { id: 4, name: 'Χρηστες', url: '/users' },
];
const settings = ['Προφίλ', 'Αποσύνδεση'];

function ResponsiveAppBar() {
  //{isLoggedIn}: {isLoggedIn: boolean}

  const { isLoggedIn } = React.useContext(AuthContext);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const admin = JSON.parse(localStorage.getItem('admin') || 'false');

  const navigate = useNavigate();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logoutUser = async () => {
    try {
      if (isLoggedIn) {
        setIsLoading(true);
        localStorage.removeItem('token');
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setIsLoading(false);
        navigate(0);
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const filteredPages = admin
    ? pages //id=1 ειναι για το Categories και id=2 για τo Users
    : pages.filter((page) => page.id === 1 || page.id === 2); //id=3 ειναι για το Projects και id=4 για τo Δημιουργια Project

  return !isLoading ? (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: 2,
      }}
    >
      <CircularProgress size={30} />
    </Box>
  ) : (
    <AppBar position='static' sx={{ backgroundColor: '#BA1E4C' }}>
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <img
            src={logo}
            style={{ marginLeft: '40px', width: 50, height: 50 }}
            alt='Film Cluster!'
          />
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {filteredPages.map((page, index) => (
                <MenuItem key={index} onClick={handleCloseNavMenu}>
                  <Link
                    to={page.url}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    {page.name}
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {filteredPages.map((page, index) => (
              <Button
                key={index}
                component={Link}
                to={page.url}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title='Open settings'>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar src='/broken-image.jpg' />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id='menu-appbar'
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    if (setting === 'Αποσύνδεση') {
                      logoutUser();
                    } else if (setting === 'Προφίλ') {
                      navigate('/user_profile');
                    } else {
                      handleCloseUserMenu();
                    }
                  }}
                >
                  <Typography textAlign='center'>{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
