import '../design.css';
import 'react-toastify/dist/ReactToastify.css';
import { DataGrid, GridCellParams, GridColDef, GridValueGetterParams, gridVisibleColumnDefinitionsSelector } from '@mui/x-data-grid';
import { Box, Button, Card, CardContent, CircularProgress, IconButton, Modal, TextField, Tooltip, Typography } from '@mui/material';
import { DataTable } from '../display';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Messages from '../messages';
import { httpClient } from '../requests';
import * as yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import DeleteIcon from '@mui/icons-material/Delete';
import { Navigate, useNavigate } from 'react-router-dom';
import { gigaPixelsToPixels, pixelsToGigaPixels } from '../components/PixelsConverter';
import * as Important from "../important";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';



const CategorySchema = yup.object().shape({
  name: yup.string().required("Ξεχάσατε να βάλετε όνομα κατηγορίας!"),
  gPixels: yup.number().required('Ξεχάσατε να εκχωρήσετε τον αριθμό των GPixels!').positive('Τα GPixels πρεπει να μην είναι αρνητικός αριθμός'),
})


export function CategoryTable(): JSX.Element {


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));


  const navigate = useNavigate();
  const usersUrl = Important.userUrl;

  const [result, setResult] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [newCategory, setNewCategory] = useState('');
  const [refetch, setRefetch] = useState<boolean>(false);
  const [editCategoryId, setEditCategoryId] = useState<number | null>(null);

  const admin = JSON.parse(localStorage.getItem('admin') || 'false');


  const { setValue, handleSubmit, formState: { errors }, reset, control, register } = useForm({
    defaultValues: {
      name: "",
      gPixels: 0,
    },
    resolver: yupResolver(CategorySchema),
  });


  const openModal = () => {
    setIsModalOpen(true);
  }

  const closeModal = () => {
    setIsModalOpen(false);
    setNewCategory('');
  }

  
  

  
  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewCategory(event.target.value);
  };

  const handleCreateCategory = () => {
    setEditCategoryId(null);
    reset({ name: "", gPixels: 0 });
    openModal();
  };


  const handleEditCategory = (category: any) => {
    setValue('name', category.name);
    setValue('gPixels', category.pixels); 
    
    setEditCategoryId(category.id);
    setIsModalOpen(true);
  };
  


  const handleDeleteCategory = async (categoryId: number) => {
    
    try {
      await httpClient.delete(`category/${categoryId}`);
      toast.success('Η κατηγορία σας διαγράφηκε επιτυχώς!');
      setRefetch(!refetch);
    } catch(error) {
      toast.error('Αποτυχία διαγραφής κατηγορίας');
    }
  }


  
  
  const handleFormSubmit = handleSubmit((data) => {
    try {
      const categoryData = {
        name: data.name,
        pixels: gigaPixelsToPixels(data.gPixels), 
      };
  
      if (editCategoryId !== null) {
        updateCategory(editCategoryId, categoryData);
      } else {
        createCategory(categoryData);
      }
  
      closeModal();
    } catch (error) {
      throw error;
    }
  });
  

  

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id',  minWidth: 100},
    { field: 'name', headerName: 'Όνομα',  minWidth: 180},
    { field: 'pixels', headerName: 'GPixels', minWidth: 90 },
    { field: 'user_count', headerName: 'Χρήστες',  minWidth: 150 },
    { field: 'enabled_users', headerName: 'Ενεργοί Χρήστες',  minWidth: 150},
    { field: 'disabled_users', headerName: 'Ανενεργοί Χρήστες',  minWidth: 150},
    { field: 'bind_px', headerName: 'Δεσμευμενα GPixel',  minWidth: 150,
        renderCell: (params) => (
          <div>
            {params.row.pixels * params.row.user_count}
          </div>
        )},
    { field: 'active_bind_px', headerName: 'Δεσμευμενα GPixel Ενεργών',  minWidth: 210,
        renderCell: (params) => (
          <div>
            {params.row.pixels * params.row.enabled_users}
          </div>
    )},
    { field: 'used_pixels', headerName:'Καταναλωμενα GPixel', minWidth:150},
    { field: 'edit', headerName: 'Επεξεργασία',  minWidth: 110,
        renderCell: (params:GridCellParams) => (
          <IconButton sx={[{opacity: 0.8, color:'#BA1E4C'}]} onClick={() => handleEditCategory(params.row)}>
            <PermDataSettingIcon />
          </IconButton>
          
    )},
    { field: 'delete', headerName: 'Διαγραφή',  minWidth: 110,
        renderCell: (params) => {
          
          const checkUsersExist = params.row.user_count > 0;

          return checkUsersExist ?(
            <Tooltip title="Δεν μπορείτε να διαγράψετε, υπάρχουν χρήστες σε αυτή την κατηγορία">
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) :(
            <IconButton color="warning" onClick={() => handleDeleteCategory(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          )
          
          }
    },
  ];

  

  const displayDivs = (divs: any[]) => (
    //<div className="result-container centered-table">
    
      <DataTable
        rows={divs.map((row) => ({
          ...row,
         
          edit: null,
          delete:null,
        }))}
        tableColumns={columns}
      />
      
    //</div>
  );

  

  async function getCategories() {
    try {
      setIsLoading(true);
      const response: any = await httpClient.get('category');

      const userCounts = await getUserCounts();

      const usedCatpixels = await getUsedPixels();

     
      
      const result = response.data.map((item: any) => {
        const { id, name, pixels } = item;

        const userCount = userCounts.find((category: any) => category.name === name)?.totalusers || 0;
        const enabledUsers = userCounts.find((category: any) => category.name === name)?.enabledusers || 0;
        const disabledUsers = userCounts.find((category: any) => category.name === name)?.disabledusers || 0;
        const usedPixels = usedCatpixels.find((category:any) => category.name ===name)?.totalusedpixels || 0;


    

        return {
          id,
          name,
          pixels: pixelsToGigaPixels(pixels),
          user_count: userCount,
          enabled_users: enabledUsers,
          disabled_users: disabledUsers,
          used_pixels: pixelsToGigaPixels(usedPixels).toFixed(3),
        };
      });
      
      setResult(result);
  
    } catch (error: any) {
      return [];
      //toast.error(error.response?.data.message || Messages.getCategoryError);
    } finally {
      setIsLoading(false);
    }
  }

  async function getUserCounts() {
    try{
      const response = await httpClient.get('category/user-count');
      
      return response.data
    }catch(error){
      console.error("Error for user-count: ", error);
      return [];
    }  
    
  }

  async function getUsedPixels() {
    try {
      const response: any = await httpClient.get('category/pixels/used-pixels');
      
      return response.data;
    }
    catch (error: any) {
      console.error(error);
      return [];
    }
  }

  async function createCategory(data:any) {
    try {
      
      const response = await httpClient.post('category', {
        name: data.name,
        pixels: data.pixels,
      });
      toast.success('Η κατηγορία σας δημιουργήθηκε με επιτυχία');
      setRefetch(!refetch);
      closeModal();
    } catch (error) {
      toast.error('Αποτυχία στην δημιουργία κατηγορίας');
    } 
  }


  async function updateCategory(categoryId: number, data:any){

    try{
      
      const response = await httpClient.patch(`category/${categoryId}`, {
        name: data.name,
        pixels: data.pixels,
      });
      toast.success('Η κατηγορία σας τροποποιήθηκε με επιτυχία');
      setRefetch(!refetch);
      closeModal();
      

    }catch(error){
      toast.error('Η επεξεργασία της κατηγορίας απέτυχε. Προσπαθήστε ξανά!')
    }
  }



  useEffect(() => {
    
    getCategories();
  }, [refetch]);


  const totalUserCount = result.reduce((acc, curr) => acc + parseInt(curr.user_count, 10), 0);

  const totalBindPx = result.reduce((acc, curr) => acc + (curr.pixels * curr.user_count), 0);
  const totalUsedPixels = result.reduce((acc, curr) => acc + parseFloat(curr.used_pixels), 0);



  return <> {admin ? (
      <div>
        {isLoading ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2 }}>
            <CircularProgress size={30} sx={{color:'#BA1E4C'}}/>
          </Box>
        ): (
          <>
          
            <Box
              mt={5}
              alignItems='center'
              
              display="flex"
              justifyContent="center"
              flexDirection='column'>
            
              <Modal open={isModalOpen} onClose={closeModal}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', borderRadius: '8px', boxShadow: 24, p: 4, minWidth: 100 }}>
                  <Typography variant="h6">
                    {editCategoryId !== null ? 'Επεξεργασία κατηγορίας' : 'Δημιουργία κατηγορίας'}
                    </Typography>
                  <form onSubmit={handleFormSubmit}>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Όνομα κατηγορίας"
                        fullWidth
                        margin="normal"
                        error={!!errors.name}
                        helperText={errors.name?.message}
                      />
                    )}
                  />
                  <Controller
                    name="gPixels"
                    control={control}
                    defaultValue={0}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        label="GPixels κατηγορίας"
                        fullWidth
                        margin="normal"
                        error={!!errors.gPixels}
                        helperText={errors.gPixels?.message}
                        inputProps={{ step: 'any' }}
                      />
                    )}
                  /> 
                
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                  <Button variant="contained" sx={[{backgroundColor: '#BA1E4C'}, {'&:hover': {backgroundColor: '#BA1E4C', color: 'white'}}]} type="submit" >
                  {editCategoryId !== null ? 'ΤΕΛΟΣ' : 'ΔΗΜΙΟΥΡΓΙΑ'}
                  </Button>
                  <Button sx={[{ ml: 2,borderColor:'#BA1E4C', color: '#BA1E4C' },{'&:hover': {borderColor:'#BA1E4C', color:'white',backgroundColor:'#BA1E4C'}}]} variant="outlined" onClick={closeModal}> 
                    ΑΚΥΡΩΣΗ
                  </Button>
                </Box>
                </form>
              </Box>
            </Modal>
  
  
            <Box display="flex" alignItems="center" flexDirection={isMobile ? 'column' : 'row'} sx={{ justifyContent: 'space-between'}}>
              <Typography variant="h5" sx={{  flexGrow: 1,  mb: isMobile ? 1 : 0 }} >
                Οι κατηγορίες σας
              </Typography>
              <Button onClick={handleCreateCategory} variant="contained" sx={[{ml: isMobile ? 0 : 2,backgroundColor: '#BA1E4C'}, {'&:hover': {backgroundColor: '#BA1E4C', color: 'white'}}]} endIcon={<AddIcon />}>
                  ΚΑΤΗΓΟΡΙΑ
              </Button>
            </Box>
            </Box>
              <div style={{ display: 'flex', justifyContent:'center',marginTop: "20px", width: "500" }}>
                {displayDivs(result)}
              </div>
              <Box display="flex" justifyContent="space-around" marginTop={3}>
              <Card sx={{ maxWidth: 345 }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Συνολικοί χρήστες
                  </Typography>
                  <Typography variant="h6">
                    {totalUserCount}
                  </Typography>
                </CardContent>
              </Card>

              <Card sx={{ maxWidth: 345 }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Συνολικά δεσμευμένα <br/> 
                    GPixel
                  </Typography>
                  <Typography variant="h6">
                  {totalBindPx.toFixed(1)} 
                  </Typography>
                </CardContent>
              </Card>

              <Card sx={{ maxWidth: 345 }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Συνολικά καταναλωμένα <br/>
                    GPixel
                  </Typography>
                  <Typography variant="h6">
                    {totalUsedPixels.toFixed(2)} 
                  </Typography>
                </CardContent>
              </Card>
            </Box>

          </>
        )}
        
      </div>) : (<Navigate to="/projects" />)}
  </>;
}