import * as Important from "./important";
import * as Messages from "./messages";
import { httpClient } from './requests';
import { toast } from 'react-toastify';

const pixelsRequestUrl=Important.pixelsUrl;

export async function getUserPixels(limit: boolean, asAdmin: boolean, userId: number) {
    let url: string = pixelsRequestUrl;
    let lastUrlString = "";
    try {
      if(asAdmin) {
        url +="/asadmin";
        lastUrlString = "?userId="+userId;
      }
      if (limit) url += "/limit";
      else url += "/used";
      url+=lastUrlString;
      const response = await httpClient.get(url);
      return response.data;
    }
    catch (error) {
      console.log(error);
      return [];
      //toast.error(Messages.getUserPixelsError);
    }
}


