import React, { createContext, useEffect, useState } from 'react';
import { HashRouter, Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Submissions} from './pages/submissions'; 
import { Details } from './pages/details';
import { Projects } from './pages/projects';
import { Users } from './pages/users';
import { UserProfile } from './pages/user_profile';
import ResponsiveAppBar from "./components/AppBar";
import SignInSide from './authentication/signIn';
import RegisterSide from './authentication/register';
import { PrivateRoutes } from './PrivateRoutes';
import {CategoryTable} from './pages/categories';
import { Home } from './home/Home';
import ForgotPassword from './authentication/forgotPage';
import ResetPassword from './authentication/resetPassword';


export const AuthContext = createContext({
  isLoggedIn: false,
  setIsLoggedIn: (loggedIn: boolean) => {}
});


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(!!localStorage.getItem('token'));
  const navigate = useNavigate();
  
  const publicRoutes = ["/signin", "/forgot-password", "/reset-password", "/register"];

  useEffect(() => {
    const token = localStorage.getItem('token');
    const currentHash = window.location.hash.split('?')[0];

    if (!token && !publicRoutes.includes(currentHash)) { 
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      <div className="App">
      {isLoggedIn && <ResponsiveAppBar />}
        <Routes>
          <Route path="/signin" element={<SignInSide />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/register" element={<RegisterSide />} />
          <Route path="/" element={<PrivateRoutes />}>
            <Route path="/" element={<Home />} />
            <Route path="/submissions" element={<Submissions />} />
            <Route path="/details" element={<Details />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/categories" element={<CategoryTable />} />
            <Route path="/users" element={<Users />} />
            <Route path="/user_profile" element={<UserProfile />} />
          </Route>
        </Routes>
        <ToastContainer />
      </div>
    </AuthContext.Provider>
  );
}


export default App;




